<template>
    <label>
        <label v-if="required" style="color:red;" title="OBLIGATORIO">*</label>
        {{ label }}
    </label>
    <div v-if="icon!=''" class="input-with-icon" :style="style">
        <span class="input-icon">
            <i :class="icon"></i>
        </span>

        <input v-model="value" class="pretorForm" ref="input" :placeholder="placeholder" style="font-size: 14px; width:100%" :disabled="disabled" autocomplete="off"
        @click="focused=true" :oninput="onInputHandler" :class="{'invalid' : !valid && value.length > 0 }" :style="style" :type="isPassword ? 'password' : type" @keydown="KeydownHandler">

        <label v-if="!valid && value.length > 0" style="color: red">{{ validateMessage }}</label>
    </div>
    <div v-else :style="style">
        <input v-model="value" class="pretorForm" ref="input" :placeholder="placeholder" style="font-size: 14px;" :disabled="disabled" autocomplete="off" @click="focused=true"
        :oninput="onInputHandler" :class="{'invalid' : !valid && value.length > 0 }" :style="style" :type="isPassword ? 'password' : type" @keydown="KeydownHandler">

        <label v-if="!valid && value.length > 0" style="color: red">{{ validateMessage }}</label>
    </div>
</template>

<script>
export default{
    name: 'PretorInput',
    props:{
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        label:{
            type: String,
            required: false,
            default: ''
        },
        placeholder:{
            type: String,
            required: false,
            default: ''
        },
        style:{
            type: String,
            required: false,
            default: ''
        },
        required:{
            type: Boolean,
            required: false,
            default: false
        },
        validate: {
            type: RegExp,
            required: false,
            default: null
        },
        icon: {
            type: String,
            required: false,
            default: ''
        },
        onInput: {
            type: Function,
            required: false,
            default: null
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        validateMessage: {
            type: String,
            required: false,
            default: ''
        },
        isPassword: {
            type: Boolean,
            required: false,
            default: false
        },
        OnEnter: {
            type: Function,
            required: false,
            default: () => {}
        }
    },
    data(){
        return {
            value: '',
            valid: false,
            showPassword: false
        }
    },
    mounted(){
        this.valid = !this.required;
    },
    methods: {
        SetFocus(){
            this.$refs.input.focus();
        },

        KeydownHandler(event){
            if(event.key == 'Enter'){
                this.OnEnter();
            }
        },

        GetValue(){
            return this.value;
        },

        SetValue(value){
            this.value = value;
            this.onInputHandler();
            this.CheckIsValid();
        },

        CheckIsValid(){
            if(this.required && !this.validate){
                this.valid =  this.value.length > 0;
                return;
            }

            if(this.validate){
                this.valid = this.validate.test(this.value);
            }
        },

        ClearField(){
            this.value = '';
            this.valid = !this.required;
            this.$refs.input.classList.remove('invalid');
        },

        onInputHandler(){
            if(!this.isPassword)
                this.value = this.value.toString().toUpperCase();

            if(this.onInput != null){
                this.onInput(this.value);
            }

            this.CheckIsValid();
        },

        GetValid(){
            if(!this.valid){
                this.$refs.input.classList.add('invalid');
            }
            return this.valid;
        }
    }
}
</script>

<style>
</style>