<template>
    <!--DESKTOP-->
    <div v-if="store.deviceWidth > 600" style="position: sticky; top: 0px;  z-index: 900">
        <header class="customNavbar sticky-navbar">
            <img src="../../images/LativoLogoWhite.png" class="logo" @click="LogoClickHandler"/>

            <button v-for="(item, index) in store.routes" :key="item.name" class="navbar-item" @click="Redirect(item.route, index)"
            @mouseover="setHovered(index)"
            @mouseout="setHovered(null)">
                <!-- <i :class="item.icon" style="font-family:20px; margin-right: 5px;"></i> -->
                {{item.name}}
                <div style="display: block; height: 1px;background-color: white;"
                    :class="currentPanel != index && hoveredIndex === index ? 'hovered' : 'notHovered'"></div>
            </button>

            <div style="margin-left: auto; display: flex; align-items: center;">
                <NotificationsMenu v-if="!store.isCustomer"/>

                <div class="dropdown" style="max-width: 200px;">
                    <button class="userButton" data-bs-toggle="dropdown" aria-expanded="false" style="margin-left: auto;">
                        <img v-if="store.LoggedUser.ProfilePicture != null" :src="store.LoggedUser.ProfilePicture">
                        <i v-else class="bi bi-person-circle" style="margin-right: 10px;"></i>

                            {{ store.LoggedUser != {} ? store.LoggedUser.Name : '' }}
                    </button>
                    <div class="dropdown-menu userButtonMenu" style="width: 200px;" @click="logout">
                        <i class="bi bi-box-arrow-right" style="margin-right: 5px;"></i>
                        CERRAR SESIÓN
                    </div>
                </div>
            </div>
        </header>
    </div>
    <!--MOBILE-->
    <div v-else  style="position: sticky; top: 0px; z-index: 900 !important;">
        <header class="customNavbar sticky-navbar" style="justify-content: center; width: 100%">

            <button style="background-color: transparent; border: none; position: absolute; left: 0px;">
                <i style="color: rgb(245, 245, 245); font-size: 30px !important;" :class="'bi bi-list'"
                data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample"></i>
            </button>

            <img src="../../images/LativoLogoWhite.png" class="logo" style="margin: 0px;"/>

            <div style="display: flex; position: absolute; right: 10px;">
                <NotificationsMenu v-if="!store.isCustomer"/>

                <div class="dropdown" style="max-width: 200px;">
                    <button class="userButton mobile" data-bs-toggle="dropdown" aria-expanded="false">
                        <img v-if="store.LoggedUser.profilePhoto">
                        <i v-else class="bi bi-person-circle"></i>
                    </button>
                    
                    <div class="dropdown-menu userButtonMenu" style="width: 200px;" @click="logout">
                        <i class="bi bi-box-arrow-right" style="margin-right: 5px;"></i>
                        CERRAR SESIÓN
                    </div>
                </div>
            </div>

        </header>

        <div class="offcanvas offcanvas-start pretorOffCanva" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">

            <div data-bs-dismiss="offcanvas" aria-label="Close" ref="btnCloseOffcanva"
                    style="color: white; position: absolute !important; top: 15px !important; left: 25px !important; background-color: none; padding-top: 0px;">
                    <i class="bi bi-arrow-left" style="font-size: 24px; margin-right: 5px;"></i>
                </div>

            <div class="offcanvas-body" style="background-color: rgb(31, 31, 44, 0.9); color: rgb(245, 245, 245)">

                <div style="display: flex; flex-direction: column; padding: 20px;">
                    <button v-for="item in items" :key="item.name" class="navbar-item mobile" style="margin-bottom: 10px;" @click="Redirect(item.route)">
                        <i :class="item.icon" style="font-size:20px; margin-right: 5px;"></i>
                        {{item.name}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from '@/store/DefaultStore.js';
import { useRouter } from 'vue-router';
import NotificationsMenu from './NotificationsMenu.vue';
import { getAuth, signOut } from 'firebase/auth';
//import { inject } from 'vue';
import { useToast } from 'vue-toastification';

export default{
    name: 'NavBar',
    components: {
        NotificationsMenu
    },

    setup(){
        const store = useStore();
        const router = useRouter();
        const auth = getAuth();
        const toast = useToast();

        return{
            store, router, auth, toast
        }
    },
    data(){
        return {
            items: [
                {name: 'INICIO', icon: 'bi bi-house', route: 'home'},
                {name: 'CALENDARIO', icon: 'bi bi-calendar3', route: 'calendar'},
                {name: 'ACUERDOS', icon: 'bi bi-card-list', route: 'agreements'},
                {name: 'EXPEDIENTES', icon: 'bi bi-files',  route: 'files'},
            ],
            hoveredIndex: null,
            currentPanel: 0
        }
    },
    beforeMount(){
        if(this.store.isCustomer){
            this.items = [{name: 'EXPEDIENTES', icon: 'bi bi-files',  route: 'files'}]
        }

        if(this.store.isAdmin){
            this.items.push({name: 'USUARIOS', icon: 'bi bi-person-square', route: 'users'});
        }
    },
    methods:{
        LogoClickHandler(){
            this.router.push({ name: 'home' });
        },

        Redirect(route, index){
            this.currentPanel = index
            this.router.push({ name: route });

            if(this.$refs.btnCloseOffcanva)
                this.$refs.btnCloseOffcanva.click();
        },

        setHovered(index) {
            this.hoveredIndex = index;
        },

        logout() {
            signOut(this.auth);
            this.toast.success('SE HA CERRADO LA SESIÓN CORRECTAMENTE', this.store.toastOptions);
        },
    }
}
</script>

<style>
.pretorOffCanva{
    background-color: transparent;
    border: none !important;
}

.pretorOffCanva div{
    padding-top: 80px;
}

.customNavbar{
    background-color: var(--LativoDarkPurple);
    color: white;
    width: 100%;
    height: 60px;
    display: flex;
    position: sticky;
    top: 0px;
    align-items: center;
}

.navbar-item{
    margin-left:30px;
    background-color: transparent;
    font-size: 14px;
    height: 60px;
    border-radius: 10px;
    border: none;
    color: white;
    cursor: pointer;
}

.navbar-item.mobile{
    margin-left: 0px !important;
    font-size: 18px !important;
    margin-bottom: 30px !important;
}

.logo{
    height: 20px;
    margin-left: 20px;
    cursor: pointer;
}

.userButton{
    background-color: transparent;
    border: none;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 15px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 15px;

    position:relative;
    margin-left: auto;
    height: 40px;
}

.userButton.mobile{
    margin-right: 0px !important;
    border-radius: 50%;
    width: 40px;
}

.userButton:hover{
    background-color: rgb(40, 40, 40);
}

.userButton img{
    margin-right: 10px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
}

.userButton i{
    font-size: 18px;
}

.userButton.mobile i{
    margin-right: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.userButtonMenu{
    cursor: pointer;
    background-color: var(--LativoDarkPurple);
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.userButtonMenu:hover{
    background-color: rgb(62, 62, 62);
}

.hovered{
    animation: startHover 0.3s forwards;
}

.notHovered{
    animation: endHover 0.3s forwards;
}

@keyframes startHover{
    from{
        opacity: 0;
        width: 0;
    }
    to{
        opacity: 1;
        width: 100%;
    }
}

@keyframes endHover{
    from{
        opacity: 1;
        width: 100%;
    }
    to{
        opacity: 0;
        width: 0;
    }
}

</style>