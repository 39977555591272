import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia';
import Toast from 'vue-toastification'
import "vue-toastification/dist/index.css";
import VueSweetalert2 from 'vue-sweetalert2';
import "sweetalert2/dist/sweetalert2.min.css";

const pinia = createPinia();

const app = createApp(App);

app.use(pinia);
app.use(Toast, {
    transition: "Vue-Toastification__fade",
    maxToasts: 3,
    newestOnTop: true
});
app.use(VueSweetalert2);
app.use(router);

app.mount('#app')

export default app