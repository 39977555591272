import RequestSender from './RequestSender.js';

function ValidateInvitation(data) {
    return RequestSender.Get('authentication/ValidateInvitation', data);
}

function CheckUserSession(data){
    return RequestSender.Post('authentication/CheckUserSession', data);
}

function AddSecretKey(data){
    return RequestSender.Post('authentication/AddSecretKey', data);
}

function RemoveSecretKey(){
    return RequestSender.Post('authentication/RemoveSecretKey');
}

function KillSession(){
    return RequestSender.Post('authentication/KillSession')
}

export default {
    ValidateInvitation, CheckUserSession, AddSecretKey, RemoveSecretKey, KillSession
}