import RequestSender from './RequestSender.js';

function CheckUserSession(data){
    return RequestSender.Post('user/CheckUserSession', data);
}

function GetAvailableUsers() {
    return RequestSender.Get('user/GetAvailableUsers');
}

function GetListOfUsers() {
    return RequestSender.Get('user/GetListOfUsers');
}

function GetCurrentUserData(){
    return RequestSender.Get('user/GetCurrentUserData');
}

function GetAllUserTypes() {
    return RequestSender.Get('user/GetAllUserTypes');
}

function GetUsersMessageData(data){
    return RequestSender.Get('user/GetUsersMessageData', data);
}

function GetUserDetail(PKUser){
    return RequestSender.Get('user/GetUserDetail', PKUser);
}

function AddUser(user){
    return RequestSender.Post('user/AddUser', user);
}

function EditUser(user){
    return RequestSender.Post('user/EditUser', user);
}

function AddSecretKey(secret){
    return RequestSender.Post('user/AddSecretKey', secret);
}

function KillSession(){
    return RequestSender.Post('user/KillSession');
}

function GetUserAccessToPage(data){
    return RequestSender.Get('user/GetUserAccessToPage', data)
}

function DeleteUser(user){
    return RequestSender.Post('user/DeleteUser', user);
}

function AddUserInvitation(data){
    return RequestSender.Post('user/AddUserInvitation', data);
}

function GetUserAlreadyExists(data){
    return RequestSender.Get('user/GetUserAlreadyExists', data);
}

function GetInvitedUsers(){
    return RequestSender.Get('user/GetInvitedUsers');
}

function GetInvitedUserDetail(data){
    return RequestSender.Get('user/GetInvitedUserDetail', data);
}

function ResendInvitation(data){
    return RequestSender.Post('user/ResendInvitation', data);
}

function CancelInvitation(data){
    return RequestSender.Post('user/CancelInvitation', data);
}

function EditInvitedUser(data){
    return RequestSender.Post('user/EditInvitedUser', data);
}

export default {
    CheckUserSession, GetAvailableUsers, GetListOfUsers, GetCurrentUserData, GetAllUserTypes, DeleteUser,
    GetUsersMessageData, GetUserDetail, AddUser, EditUser, KillSession, AddSecretKey, GetUserAccessToPage,
    AddUserInvitation, GetUserAlreadyExists, GetInvitedUsers, GetInvitedUserDetail, ResendInvitation, CancelInvitation, EditInvitedUser
}