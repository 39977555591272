import { defineStore } from 'pinia'
//import UserService from '@/services/UserService';
//import { getAuth, signOut } from 'firebase/auth';

export const useStore = defineStore({  
  id: 'store',
  state: () => (
    { 
      isLoading : false,
      deviceWidth : 0,
      LoggedUser: null,
      isCustomer: false,
      isAdmin: false,
      routes: [],
      //QUTIAR HARDCODE
      // LoggedUser: {
      //   Name: 'Oscar Magaña',
      //   PKUser: 1,
      //   ProfilePicture: null,
      //   IsClient: false
      // },
      toastOptions : {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      }        
    }
  ),
  actions: {
    SetLoader(isLoading) {
      this.isLoading = isLoading;
    },
    SetDeviceWidth(deviceWidth) {
      this.deviceWidth = deviceWidth;

      if(this.deviceWidth > 600)
        document.body.style.overflow = "hidden";
      else
        document.body.style.overflow = "auto";

    },
    SetLoggedUser(userData){
      if(userData == null){
        this.LoggedUser = {
          Name: '',
          PKUser: null,
          ProfilePicture: null,
          Role: null
        }

        return;
      }
      else
        this.LoggedUser = userData;

      this.isCustomer = this.LoggedUser.Role == 'CUSTOMER';
      this.isAdmin = this.LoggedUser.Role == 'ADMINISTRADOR';

      if(this.isCustomer){
        this.routes = [{name: 'EXPEDIENTES', icon: 'bi bi-files',  route: 'files'}];
        return;
      }

      this.routes = [
        {name: 'INICIO', icon: 'bi bi-house', route: 'home'},
        {name: 'CALENDARIO', icon: 'bi bi-calendar3', route: 'calendar'},
        {name: 'ACUERDOS', icon: 'bi bi-card-list', route: 'agreements'},
        {name: 'EXPEDIENTES', icon: 'bi bi-files',  route: 'files'}
      ];

      if(this.isAdmin){
        this.routes.push({name: 'USUARIOS', icon: 'bi bi-person-square',  route: 'users'});
      }
    }
  }
})