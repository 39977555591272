//const baseUrl = 'http://localhost:8080/api/public/api/';
const baseUrl = 'https://stgpretor.byteboxsoft.com/apipretor/public/api/';

function Post(url, data){
    url = baseUrl + url;
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        return error;
    });
}

function Get(url, data = null){
    url = baseUrl + url;

    if(data != null)
        url += '?' + new URLSearchParams(data).toString();
        
    let headers = {
        'Content-Type': 'application/json'
    };

    return fetch(url, {
        method: 'GET',
        headers: headers
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        return error;
    });
}

export default {
    Post, Get
}