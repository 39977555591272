<template>
    <div class="dropdown" style="margin-left: auto; max-width: 200px;">
        <button ref="notificationButton" class="userButton" :class="{'mobile' : store.deviceWidth < 600}" data-bs-toggle="dropdown" aria-expanded="false" @click="NotificationButtonHandler">
            <!-- <img v-if="store.LoggedUser.profilePhoto"> -->
            <div v-if="notificationCount>0" class="notification">
                {{ notificationCount }}
            </div>
            <i class="bi bi-bell-fill"></i>
        </button>
        <div class="dropdown-menu notificationMenu" :class="{'mobile' : store.deviceWidth < 600}" style="position: sticky; z-index: 9999; ">
            <div style="margin: 5px; margin-left: 15px;">
                <b>NOTIFICACIONES</b>
            </div>

            <div v-for="notification in notifications" :key="notification.ID"  class="notificationCard" style="align-items: center;" @click="ClickHandler(notification)">
                <div style="position: relative; width: 54px; margin-right: 10px; display: flex;">
                    <img :src="notification.ProfilePicture != null ? notification.ProfilePicture : 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png'" 
                    style="width: 48px; height: 48px; border-radius: 50%; top:0; left: 0"/>

                    <div style="position: absolute; bottom: 0; right: 0; background-color: rgb(46, 137, 255); display: inline-block; border-radius: 50%; width: 24px; height: 24px;">
                        <i :class="notification.Icon" style="margin-right: 0 !important; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 14px !important;"></i>
                    </div>
                </div>
                <div style="max-height: auto; position: relative">
                    <div>
                        <b>EXP. {{ notification.FileNumber }}</b>
                    </div>
                    <div>
                        {{ notification.Message }}
                    </div>
                    <div>
                        {{ notification.Date }}
                    </div>
                </div>
                <div v-if="!notification.IsRead" style="display: block; margin-left:auto; background-color: rgb(46, 137, 255); border-radius: 50%; width: 12px; height: 12px; transform: translate(-50%); right: 15px;">
                </div>
            </div>

            <div style="width: 100%; display: flex; justify-content: center;">
                <div ref="notificationLoader" style="display: block; width: 1px; height: 1px; background-color: transparent;"></div>
                <div v-if="(loading && !endOfNotifications) || (!endOfNotifications && notifications.length==0)" class="spinner-border text-light"></div>
            </div>
        </div>
    </div>
</template>

<script>
import NotificationService from '@/services/NotificationService.js';
import { useRouter } from 'vue-router';
import { useStore } from '@/store/DefaultStore';
import { collection, onSnapshot, orderBy, query, limit, getDocs, where } from "firebase/firestore"; 
import { db } from '@/authentication/firebase.js'
import { onUnmounted } from 'vue';

export default{
    data(){
        return{
            notificationCount: 0,
            filesChatsNotifications: [],
            notificationMenuIsShown: false,
            notifications: [],
            lastNotificationID: null,
            loading: false,
            endOfNotifications: false,
            loaderObserver: null,
            firstNotification: true
        }
    },

    props:{
        ShowNotification: {
            type: Function,
            required: false
        }
    },

    setup(){
        const router = useRouter();
        const store = useStore();

        return{
            router, store
        }
    },

    mounted(){
        //Observador de spinning para cargar mas mensajes
        const targetElement = this.$refs.notificationLoader;
        const options = {
            rootMargin: '0px',
            threshold: 0.5
        };

        this.loaderObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !this.loading && !this.endOfNotifications){
                    this.loading = true;

                    this.LoadNotifications()
                    .then(()=>{
                        this.loading = false;

                        this.loaderObserver.unobserve(targetElement);
                        setTimeout(()=>{
                            this.loaderObserver.observe(targetElement);
                        }, 1500);
                    })
                }
            });
        }, options);
        this.loaderObserver.observe(targetElement);

        const q = query(
            collection(db, 'notifications_' + this.store.LoggedUser.PKUser),
            orderBy('Date', 'desc'),
            limit(1)
        );

        const unsub = onSnapshot(q, () => {
            if(this.firstNotification){
                this.firstNotification = false;
                return;
            }

            this.notificationCount++;
        });

        onUnmounted(unsub);

        let qu = query(
            collection(db, 'notifications_' + this.store.LoggedUser.PKUser),
            where('IsRead', '==', false));

        getDocs(qu).
        then(results => {
            this.notificationCount += results.docs.length;
        });
    },

    beforeUnmount(){
        this.loaderObserver.disconnect();
    },

    methods: {
        ClickHandler(notification){
            NotificationService.SetNotificationRead(this.store.LoggedUser.PKUser, notification.ID);
           
            // if(notification.Type == 'task'){
            //     this.router.push({ name: 'fileDetail', 
            //     params: {
            //         PKFile: notification.File.PKFile,
            //         PKTask: 13
            //     },
            //     props: {
            //         PKTask: 13
            //     }});
            // }
            this.router.push({ name: 'fileDetail', params: { PKFile: notification.FKFile }})
        },

        NotificationButtonHandler(){
            this.notificationMenuIsShown = !this.notificationMenuIsShown;

            if(!this.notificationMenuIsShown){
                //this.notifications = [];
                this.lastNotificationID = null;
                this.endOfNotifications = false;
                this.loading = false;
            }
            else{
                this.notificationCount = 0;
            }
        },

        LoadNotifications(){
            return new Promise((resolve, reject) => {
                NotificationService.GetNotifications(this.store.LoggedUser.PKUser, this.lastNotificationID)
                .then(data => {
                    if(!data.success){
                        this.ShowNotification('error', 'OCURRIÓ UN ERROR AL CARGAR LAS NOTIFICACIONES');
                        this.loading = false;
                        this.$refs.notificationButton.click();
                        return;
                    }

                    this.endOfNotifications = data.Notifications.length < 25;

                    if(data.Notifications.length > 0){
                        this.lastNotificationID = data.Notifications[data.Notifications.length - 1].ID;
                    }

                    this.notifications = this.notifications.concat(data.Notifications);

                    this.loading = false;
                    resolve();
                })
                .catch(() => {
                    this.loading = false;
                    this.ShowNotification('error', 'OCURRIÓ UN ERROR AL CARGAR LAS NOTIFICACIONES');
                    this.$refs.notificationButton.click();
                    reject();
                })
            })
        }
    }
}
</script>

<style>
.notificationMenu{
    width: 400px; 
    max-width: 400px;
    height: 400px;
    max-height: 400px;
    background-color: var(--LativoDarkPurple);
    border-radius: 10px;
    font-size:14px !important;
    overflow-y: scroll;
    z-index: 9000 !important;
}

.notificationMenu.mobile{
    width: 100vw;
}

.notificationMenu b{
    color: white;
    font-size: 16px !important;
}

.notificationCard{
    width: 100%;
    color: white;
    display: flex;
    padding: 10px;
    cursor: pointer;
    max-height: auto !important;
    height: auto !important;
}

.notificationCard:hover{
    background-color: rgb(58, 59, 60);
}

.notification{
    border-radius: 50%; 
    background-color: red;
    color: white;
    top: 5px;
    right: 5px;
    position: absolute;
    z-index: 100;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
</style>