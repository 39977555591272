import RequestSender from './RequestSender.js';
import { db } from '@/authentication/firebase.js';
import { collection, addDoc, Timestamp, limit, query, orderBy, startAfter, getDocs, doc, updateDoc, where } from "firebase/firestore"; 
import FilesService from './FilesService.js';

async function AddNotification({FKUser, field, value, recipients = null, PKFile = null}){
    try{
        if(recipients == null){
            await FilesService.GetPinnedUsers({PKFile: PKFile})
            .then(data => {
                recipients = data.PinnedUsers.filter(x => x != FKUser);
            })
            .catch(() => {
                return false;
            });
        }

        let notification = {
            FKFile: PKFile,
            FKUser: FKUser,
            Date: Timestamp.fromDate(new Date()),
            IsRead: false
        }
        notification[field] = value;

        try{
            recipients.forEach(async x =>{
                await addDoc(collection(db, `notifications_${x}`), notification);
            });
            return true;
        }
        catch(e){
            return false;
        }
    }
    catch(e){
        return false;
    }
}

function GetFormatedNotifications(data){
    return RequestSender.Post('notification/GetFormatedNotifications', data);
}

function UpdateUnreadMessages(PKUser, PKFile, PKSenderUser, amount = null){
    return new Promise((resolve, reject) => {
        try{
            let q = query(
                collection(db, `notifications_${PKUser}`),
                where('PKFileChat', '==', PKFile),
            );

            getDocs(q)
            .then(results => {
                //No existe
                if(results.docs.length == 0){
                    let unreadMessage = {
                        FKFileChat: PKFile,
                        FKSenderUser: PKSenderUser,
                        UnreadMessages: 1,
                        Date: Timestamp.fromDate(new Date()),
                    }

                    addDoc(collection(db, `notifications_${PKUser}`), unreadMessage)
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
                }
                else{
                    let unreadMessage = results.docs[0].data();

                    updateDoc(doc(db, `notifications_${PKUser}`, results.docs[0].id), {
                        UnreadMessages: amount == null ? unreadMessage.UnreadMessages + 1 : amount,
                        FKSenderUser: PKSenderUser,
                        Date: Timestamp.fromDate(new Date()),
                    })
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
                }
            })
            .catch(() => {
                reject();
            });
        }
        catch(e){
            reject(e);
        }
    });
}

function GetNotifications(PKUser, lastNotificationID = null){
    return new Promise((resolve, reject) => {
        try{
            let q = query(
                collection(db, `notifications_${PKUser}`),
                orderBy('Date', 'desc'),
                lastNotificationID != null ? startAfter(lastNotificationID) : limit(25),
                limit(15)
            )

            getDocs(q)
            .then(results => {
                let notifications = results.docs.map(x=>{
                    let data = x.data();

                    data.ID = x.id;
                    data.Date = FormatDate(data.Date.toDate());

                    return data;
                });

                GetFormatedNotifications({notifications: notifications})
                .then(data => {
                    resolve(data);
                })
                .catch(() => {
                    reject();
                })
            })
            .catch(() => {
                reject();
            });
        }
        catch(e){
            reject(e);
        }
    });
}

function SetNotificationRead(PKUser, notificationID){
    return new Promise((resolve, reject) => {
        try{
            updateDoc(doc(db, `notifications_${PKUser}`, notificationID), { IsRead: true })
            .then(() => {
                resolve();
            })
            .catch(() => {
                reject();
            });
        }
        catch(e){
            reject(e);
        }
    });
}

//Recieves date and formats it in a way PHP can parse it
function FormatDate(date){
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
}

export default {
    AddNotification, GetNotifications, SetNotificationRead, UpdateUnreadMessages
}