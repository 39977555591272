<template>
    <button class="pretorButton" :disabled="disabled" :style="style">
        <i v-if="icon!=''" :class="icon" style="margin-right: 3px;"></i>
        {{ label }}
    </button>
</template>

<script>
export default{
    name: 'PretorButton',
    props:{
        icon: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        style: {
            type: String,
            required: false,
            default: ''
        }
    }
}
</script>

<style>
.pretorButton{
    background-color: var(--LativoDarkPurple);
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    transition: 0.3s;
    min-height: auto !important;
    max-height: max-content !important;
}
</style>