<template>
<div></div>
</template>

<script>
import Swal from 'sweetalert2'
import { useStore } from '@/store/DefaultStore.js';

export default{
    name: 'ConfirmationModal',

    setup(){
        const store = useStore();

        return{
            store
        }
    },

    methods: {
        OpenModal(message, definitive, confirmation, value){
            return new Promise((resolve) => {
                Swal.fire({
                    html:`
                    <div style="margin-bottom: 3px; display: flex; justify-content: left; margin-top: 10px;">
                        <b>CONFIRMAR ACCIÓN</b>
                    </div>
                    <div style="display: flex; justify-content: left; text-align: left">
                        ${message}
                    </div>
                    <div style="text-align: left">
                        ${definitive ? 'Esta acción será definitiva y no podrá cancelarse' : ''}
                    </div>
                    
                    <div style="display: flex; justify-content: right; margin-top: 20px;">
                        <button id="customSwalCancelButton" class="pretorButton" style="margin-right: 10px; background-color: rgb(220, 220, 220) !important; color: black !important;">
                            <i class="bi bi-x-circle" style="margin-right: 5px;"></i>
                            CANCELAR
                        </button>
                        <button id="customSwalConfirmButton" class="pretorButton" style="height: 32px !important;">
                            <i class="bi bi-check-circle" style="margin-right: 5px;"></i>
                            CONFIRMAR
                        </button>
                    </div>`,
                    showCancelButton: false,
                    showConfirmButton: false,
                    showCloseButton: true,
                    width: this.store.deviceWidth > 600 ? '40%' : '100%'
                })

                setTimeout(() => {
                    if(document.getElementById('customSwalCancelButton'))
                    document.getElementById('customSwalCancelButton').addEventListener('click', () => {
                        Swal.close();
                        resolve(false);
                    });

                    if(document.getElementById('customSwalConfirmButton'))
                    document.getElementById('customSwalConfirmButton').addEventListener('click', () => {
                        confirmation(value)
                        .then(()=>{
                            Swal.close();
                            resolve(true);
                        });
                    });
                }, 100)
            })
        },
    }

}
</script>

<style>
</style>

