<template>
  <main>
    <LoadingScreen />

    <NavBar :key="navbarKey" v-if="loggedIn"/>

    <RouterView :key="fileKey" v-if="loggedIn || signingUp"/>

    <div v-if="!loggedIn && !signingUp" class="login">

      <ConfirmationModal ref="confirmationModal"/>

      <div style="display: flex; justify-content: center; width: 100%; height: 100vh"
        :style="store.deviceWidth > 600 ? 'align-items: center;' : 'padding-top: 15%'">

          <div style="padding: 25px !important; width: 500px;">
              <div style="display: flex; width: 100%; justify-content: center; margin-bottom: 50px;">
                  <img src="../src/images/LativoLogoDarkPurple.png" style="width: 50%;"/>
              </div>

              <b>CORREO</b>
              <PretorInput ref="txtEmail" :style="'margin-bottom: 5px !important; background-color:'"
                  :validate="/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/" :placeholder="'CORREO'"
                  :validateMessage="'DEBE INGRESAR UNA DIRECCIÓN DE CORREO VÁLIDA'"/>

              <div style="width: 100%; display: flex">
                <div style="width: calc(100% - 24px)">
                  <div>
                    <b>CONTRASEÑA</b>
                    <PretorInput ref="txtPassword" :placeholder="'CONTRASEÑA'" :style="'margin-bottom: 15px !important;'" :isPassword="!showPassword" :OnEnter="SignIn"/>
                  </div>
                </div>

                <button class="iconButton" style="position: relative; left: 0px; margin-top: 25px; color: white" @click="showPassword = !showPassword">
                    <i style="font-size: 18px !important" :class="showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"></i>
                </button>
              </div>

              <PretorButton :label="'INICIAR SESIÓN'" :style="'width: 100%; height: 40px; margin-bottom: 5px;'" :icon="'bi bi-plus-circle'"  @click="SignIn"/>

              <button type="button" class="btn btn-outline-light" style="width: 100%; height: 40px; display: flex; align-items: center; justify-content: center;" @click="SignInWithGoogle">
                  <img id="googleImage" src="../src/images/officialGoogleLogo.png" class="img-fluid" style="height: 75%; margin-right: 5px;" /> INICIAR SESIÓN CON GOOGLE
              </button>

              <div style="color: white">
                <div style="margin-top: 15px;">
                  ¿Olvidaste tu contraseña?, Click <a href="#!" class="text-body" data-bs-toggle="modal" data-bs-target="#restoreModal" style="color: white !important">aquí</a> para Recuperar contraseña.
                </div>

                <div style="margin-top: 25px;">
                  ¿Todavía no tienes una cuenta? Un <b>administrador</b> del sistema debe de enviarte una invitación de registro por correo.
                  Cóntacta a uno para poder iniciar sesión.
                </div>
              </div>
          </div>
      </div>

      <button ref="openAuthenticatorModal" data-bs-toggle="modal" data-bs-target="#authenticatorModal" hidden></button>

      <!-- Modal -->
      <div class="modal fade" id="authenticatorModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable">
              <div class="modal-content">
                  <div class="modal-header">
                      <div style="display: flex; align-items: center;">
                          <img src="../src/images/authenticator-logo.png" style="height: 16px; margin-right: 10px;"/>
                          <div>Doble factor de autenticación</div>
                      </div>
                      <button ref="btnCloseAuthenticationModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                      <div v-if="showQR">
                          <label style="margin-bottom: 15px;">
                            <div style="margin-bottom: 5px;">
                              Bienvenido <b>{{ toLogUser }} ({{ toLogUserEmail }})</b>
                            </div>
                              <i class="bi bi-info-circle info"></i>
                              Para iniciar sesión es necesario ingresar el <b>token</b> de <b>Google authenticator</b>, a continuación te explicamos como
                              configurar tu cuenta para obtener el <b>token de seguridad</b>.
                          </label>

                          <div style="display: flex;">
                              <label><b>1.</b></label>
                              <label>Descarga la aplicación <b>Google authenticator</b> en tu dispositivo móvil.</label>
                          </div>
                          <label><b>2.</b> Inicia sesión con alguna cuenta de <b>Google</b>.</label>

                          <div>
                              <b>3.</b> Da click en el botón <b><i class="bi bi-plus-circle"></i></b>para agregar una nueva instancia.
                          </div>

                          <label><b>4.</b> Selecciona la opción <i class="bi bi-camera" style="margin-right: 5px;"></i><i>Escanear un código QR.</i></label>
                          <label><b>5.</b> Escanea el siguiente código QR.</label>
                          <div style="display: flex;">
                              <label><b>6.</b></label>
                              <label>Tu instancia personal de Google Authenticator para <b>Lativo App</b> está lista.</label>
                          </div>

                          <img :src="QRImage" class="img-fluidrounded mx-auto d-block" alt="Responsive image" style="margin-bottom: 15px">

                          <label>¿Terminaste de configurar Google Authenticator?</label>
                          <PretorButton :label="'SIGUIENTE'" :style="'width: 100%; height: 40px; margin-top: 5px;'" :icon="'bi bi-arrow-right'" @click="AuthClickHandler"/>
                      </div>
                      <div v-else>
                          <label style="margin-bottom: 15px;">
                            <div style="margin-bottom: 5px;">
                              Bienvenido <b>{{ toLogUser }} ({{ toLogUserEmail }})</b>
                            </div>
                              <i class="bi bi-info-circle info"></i>
                              Ingresa tu token de seguridad de <b>Google authenticator</b> para iniciar sesión.
                          </label>

                          <label style="font-size: 12px; color: gray;">Token de seguridad</label>
                          <input ref="txtToken" v-model="token" type="text" pattern="[0-9]*" class="pretorForm" style="font-size: 18px; height: 50px !important; margin-bottom: 15px"
                          placeholder="Ingresa tu token de seguridad" @keydown="handleKeydown" maxlength="7">

                          <PretorButton :label="'VERIFICAR TOKEN'" :style="'width: 100%; height: 40px; margin-top: 5px;'" :icon="'bi bi-shield-check'" @click="VerifyToken"/>
                          <PretorButton v-if="!hasInstance" :label="'REGRESAR'" :style="'width: 100%; height: 40px; margin-top: 5px;'" :icon="'bi bi-arrow-left'" @click="BackHandle"/>
                          <div v-else style="margin-top: 15px;">
                              <label>¿Necesitas una nueva instancia de Google Authenticator?</label>
                              <PretorButton v :label="'RESTAURAR INSTANCIA DE GOOGLE AUTHENTICATION'"
                                  :style="'width: 100%; margin-top: 5px;'" :icon="'bi bi-x-circle'" @click="RemoveMultifactorCaller"/>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>

    <!-- Modal Restore -->
    <div class="modal fade" ref="restoreModal" id="restoreModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="restoreModalTittle">Recuperar contraseña</h1>
                <button ref="btnCloseRestoreModel" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <label style="margin-bottom: 15px;">
                  <i class="bi bi-info-circle info"></i>
                  Favor de ingresar tu correo y te enviaremos un link para restaurar tu contraseña. Este proceso solo funciona si te registraste con correo y contraseña en la plataforma.
              </label>
              <label style="font-size: 12px; color: gray;">Correo a recuperar contraseña</label>
                <input ref="txtRestore" v-model="restoreMail" type="text" class="pretorForm" style="font-size: 18px; height: 50px !important; margin-bottom: 15px"
                    placeholder="correo" v-on:keyup.enter="SendRestore" >

              <PretorButton :label="'ENVIAR CORREO PARA RECUPERACIÓN'" :style="'width: 100%; height: 40px; margin-top: 5px;'" :icon="'bi bi-send'" @click="SendRestore"/>
            </div>
            <div class="modal-footer" hidden>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-dark" data-bs-dismiss="modal" @click="restorePassword">Renviar correo de restauración</button>
            </div>
            </div>
        </div>
      </div>
  </main>
</template>

<script>
//import LoginModule from '@/components/LoginModule.vue'
import { getAuth, onAuthStateChanged, signOut, sendPasswordResetEmail } from 'firebase/auth';
import NavBar from './components/Generic/Navbar.vue'
import { RouterView } from 'vue-router'
import LoadingScreen from './components/Generic/Loading.vue'
import { useStore } from '@/store/DefaultStore.js';
import { provide } from 'vue';
import { useToast } from 'vue-toastification';
import NotificaitonService from './services/NotificationService.js';
import AuthenticationService from './services/AuthenticationService';
import { useRouter } from 'vue-router';

import PretorInput from '@/components/Generic/Input.vue';
import PretorButton from '@/components/Generic/Button.vue';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendEmailVerification } from "firebase/auth";
import UserService from '@/services/UserService';
import ConfirmationModal from '@/components/Generic/ConfirmationModal.vue';

export default {
  name: 'App',
  components: {
    NavBar, RouterView, LoadingScreen, PretorInput, PretorButton, ConfirmationModal
  },
  data() {
    return {
      array: [],
      fileKey: 0,
      navbarKey: 0,

      //QUITAR HARDCODEO
      loggedIn: false,
      signingUp: false,
      fields: ['txtEmail', 'txtPassword'],
      showQR: false,
      token: '',
      SecretKey: '',
      hasInstance: false,
      restoreMail: '',
      toLogUser: '',
      toLogUserEmail: '',
      showPassword: false,

      ShowNotificationBase : {
        'error': this.ShowErrorNotification,
        'success': this.ShowSuccessNotification,
      },

      firebaseErrors: {
        'auth/invalid-email': 'El correo electrónico no es válido.',
        'auth/user-not-found': 'El correo electrónico no está registrado.',
        'auth/wrong-password': 'La contraseña es incorrecta.',
        'auth/email-already-in-use': 'El correo electrónico ya está en uso.',
        'auth/weak-password': 'La contraseña debe tener al menos 6 caracteres.',
        'auth/too-many-requests': 'Demasiados intentos de inicio de sesión fallidos. Inténtelo de nuevo más tarde.',
        'auth/network-request-failed': 'No hay conexión a internet.',
        'auth/invalid-verification-code': 'El código de verificación no es válido.',
        'auth/invalid-verification-id': 'El código de verificación no es válido.',
        'auth/invalid-credential': 'El código de verificación no es válido.',
        'auth/credential-already-in-use': 'El código de verificación ya está en uso.'
      } 
    }
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const speakeasy = require('speakeasy');
    const qrcode = require('qrcode');
    const router = useRouter();
    const googleProvider = new GoogleAuthProvider();

    return {
      store, toast, speakeasy, qrcode, router, googleProvider
    }
  },
  
  mounted() {
    this.navbarKey++;
    this.onResize();
    window.addEventListener('resize', this.onResize);

    this.auth = getAuth();

    //Check status of the firebase auth
    //Detects when auht changes
    onAuthStateChanged(this.auth, user => {
      if(this.$route.name == 'register'){
        this.signingUp = true;
        return;
      }
      else{
        this.signingUp = false;
      }

      //If logged out return
      if(!user){
        this.KillSession();
        return;
      }

      //When logs in
      this.store.SetLoader(true);

      //Checks if the user has verified email
      if(!user.emailVerified){
        this.store.SetLoader(false);

        this.$swal({
          title: 'No verificado',
          showCancelButton: true,
          confirmButtonText: 'Reenviar correo',
          icon: 'warning'
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.sendEmailVerification(user)
          }
          return;
        }) 
      }

      //Cheks user session
      AuthenticationService.CheckUserSession({Email: user.email, Password: user.uid})
      .then(result => {
        if(result.User){
          this.loggedIn = true;
          
          this.store.SetLoader(false);

          this.store.SetLoggedUser(result.User);
          this.ShowNotification('success', 'INGRESADO CORRECTAMENTE');

          if(result.User.Role == 'CUSTOMER')
            this.router.push({ name: 'files' });
          else
            this.router.push({ name: 'home' });

          return;
        }

        //User exists but does not have a token
        if(result.success){
          this.toLogUser = result.UserName;
          this.toLogUserEmail = result.Email;

          if(result.SecretKey) {
            this.showQR = false;
            this.hasInstance = true;
            this.showImage = false;
            this.SecretKey = result.SecretKey;
          }
          else {
            this.hasInsatance = false;
            this.CreateNewQR();
          }            
          this.store.SetLoader(false);

          if(this.$refs.openAuthenticatorModal){
            //Clear the token field
            this.token = '';
            this.$refs.openAuthenticatorModal.click();
            if(result.SecretKey){
              setTimeout(()=>{
                this.$refs.txtToken.focus();
              }, 500);
            }
          }
        }
        else{
          this.$swal('Usuario desconocido', 'El usuario no está registrado en el sistema con algún rol, favor de contactar a un administrador', 'error');
          signOut(this.auth) 
          this.store.SetLoader(false);
        }
      })
      .catch(() => {
        this.ShowNotification('error', 'NO SE PUDO AUTENTICAR AL USUARIO INGRESADO');
        this.store.SetLoader(false);
      });
    });

    provide('ShowNotification', this.ShowNotification);
    provide('GetFileIcon', this.GetFileIcon);
    provide('GetFileIconColor', this.GetFileIconColor);
    provide('FormatDate', this.FormatDate);
    provide('ReadBase64File', this.ReadBase64File);
    provide('ConvertFileToBase64', this.ConvertFileToBase64);
    provide('ConvertDate', this.ConvertDate);
    provide('ShowNotificationBase', this.ShowNotificationBase);
    provide('RefreshFilePage', this.RefreshFilePage);
    provide('AddNotification', this.AddNotification);
    provide('LeaveSigningUp', this.LeaveSigningUp);
  },
  methods: {
    LeaveSigningUp(){
      this.signingUp = false;
      this.$router.replace('/')
    },

    KillSession(){
      this.store.SetLoader(true);
      AuthenticationService.KillSession()
      .then(() => {
        this.store.SetLoader(false);
        this.store.SetLoggedUser(null);

        this.loggedIn = false;

      })
      .catch(() => {
        this.store.SetLoader(false);
      })
    },

    AddNotification(notification){
      notification.FKUser = this.store.LoggedUser.PKUser;

      NotificaitonService.AddNotification(notification)
      .catch(() => {
        this.ShowNotification('error', 'OCURRIÓ UN ERROR CREANDO LA NOTIFICACIÓN')
      })
    },

    RefreshFilePage(){
      this.fileKey++;
    },

    onResize() {
      this.store.SetDeviceWidth(window.innerWidth);
    },

    //Recieves date in object and returns new JS Date Object
    ConvertDate(date){
      return new Date(date.year, date.month-1, date.day, date.hour, date.minute, 0);
    },

    //Recieves date and formats it in a way PHP can parse it
    FormatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      return formattedDate;
    },

    ShowSuccessNotification(message){
      this.toast.success(message, this.toastOptions);
    },

    ShowErrorNotification(message){
      this.toast.error(message, this.toastOptions);
    },

    ShowNotification(type, message) {
      let toasts = {
        'error': () => {
          this.toast.error(message, this.store.toastOptions);
        },
        'success': () => {
          this.toast.success(message, this.store.toastOptions);
        }
      }

      toasts[type]();
    },

    GetFileIcon(mimeType) {
      return (mimeType == 'application/pdf' || mimeType=='PDF') ? 'bi bi-file-earmark-pdf-fill' :
        (mimeType == 'image/png' || mimeType == 'image/jpeg' || mimeType=='Image') ? 'bi bi-image-fill' :
        'bi bi-file-earmark-word-fill';
    },
    GetFileIconColor(mimeType) {
      return (mimeType == 'application/pdf' || mimeType=='PDF') ? 'rgb(203, 6, 6)' :
        (mimeType == 'image/png' || mimeType == 'image/jpeg' || mimeType == 'Image') ? 'rgb(0, 0, 0)' :
        'rgb(41, 84, 151)';
    },
    ReadBase64File(content) {
      return `data:application/json;base64,${content}`;
    },
    ConvertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64Content = reader.result.split(',')[1];
           resolve(base64Content);
        };
        reader.onerror = error => {
          reject(error);
        };
      });
    },

    //LOGIN METHODS
    SendRestore(){
      sendPasswordResetEmail(this.auth, this.restoreMail)
      .then(() => {
          this.ShowNotification('success', 'SE HA ENVIADO EL CORREO DE RECUPERACIÓN CORRECTAMENTE')
          this.restoreMail = '';
          this.$refs.btnCloseRestoreModel.click();
      })
      .catch((e) => {
          let firebaseMessage = this.firebaseErrors[e.code];
          this.ShowNotification('error', firebaseMessage ? firebaseMessage : 'OCURRIÓ UN ERROR AL INTENTAR AUTENTICAR AL USUARIO');
      })
    },

        CreateNewQR(){
            this.Secret = this.speakeasy.generateSecret({name: 'Lativo Token'});
            this.SecretKey = this.Secret.hex;
            let imageCode = '';
            this.qrcode.toDataURL(this.Secret.otpauth_url, function(err, image) {
                imageCode = image;
            })
            this.QRImage = imageCode;
            this.showImage = true;
            this.showQR = true;
        },

        RemoveMultifactorCaller(){
            this.$refs.confirmationModal.OpenModal(
                '¿ESTÁ SEGURO DE QUE DESEA RESTAURAR SU INSTANCIA DE GOOGLE AUTHENTICATOR? NO PODRÁ VOLVER A INGRESAR SIN UNA NUEVA INSTANCIA',
                false,
                this.RemoveMultifactor,
                null
            );
        },

        RemoveMultifactor(){
            return new Promise((resolve, reject) => {
                this.store.SetLoader(true);

                AuthenticationService.RemoveSecretKey()
                .then(data => {
                    this.store.SetLoader(false);

                    if(!data.success){
                        this.ShowNotification('error', data.message ? data.message : 'OCURRIÓ UN ERROR AL RESTAURAR LA INSTANCIA DE GOOGLE AUTHENTICATOR');
                        resolve();
                        return;
                    }

                    this.hasInstance = false;
                    this.ShowNotification('success', 'LA INSTANCIA DE GOOGLE AUTHENTICATOR SE HA RESTAURADO CORRECTAMENTE');
                    this.CreateNewQR();
                    resolve();
                })
                .catch(() => {
                    this.store.SetLoader(false);
                    this.ShowNotification('error', 'OCURRIÓ UN ERROR AL RESTAURAR LA INSTANCIA DE GOOGLE AUTHENTICATOR');
                    reject();
                })
            })
        },

        BackHandle(){
            this.showQR = true;
        },

        async VerifyToken(){
            this.store.SetLoader(true);

            var veryfied = this.speakeasy.totp.verify({
                secret: this.SecretKey,
                encoding: 'hex',
                token: this.token.replace(' ', '')
            })

            if(veryfied) {
                await AuthenticationService.AddSecretKey({SecretKey: this.SecretKey});
                
                UserService.GetCurrentUserData()
                .then(data => {
                    this.loggedIn = true;

                    this.store.SetLoggedUser(data.User);
                    this.store.SetLoader(false);
                    this.hasInstance = true;

                    this.$refs.btnCloseAuthenticationModal.click();
                    this.ShowNotification('success', 'SE HA INICIADO SESIÓN CORRECTAMENTE');
                    

                    if(data.User.Role == 'CUSTOMER')
                      this.router.push({ name: 'files' });
                    else
                      this.router.push({ name: 'home' });
                })
                .catch((e) => {
                  let firebaseMessage = this.firebaseErrors[e.code];
                  this.ShowNotification('error', firebaseMessage ? firebaseMessage : 'OCURRIÓ UN ERROR AL INTENTAR AUTENTICAR AL USUARIO');
                  this.store.SetLoader(false);
                })
            }
            else {
                this.store.SetLoader(false);
                this.token = '';
                this.ShowNotification('error', 'TOKEN INCORRECTO');
            }
        },

        handleKeydown(event){
            if(event.key === 'Enter'){
                this.VerifyToken();
            }

            if (event.key === 'Backspace' && this.token.length == 4) {
                this.token = this.token.replace(' ', '');
            }

            if(this.token.length == 3 && event.key != 'Backspace'){
                this.token = this.token.toString() + ' ';
            }
        },

        AuthClickHandler(){
            this.showQR = false;
            this.$nextTick(()=>{
                this.$refs.txtToken.focus();
            });
        },
        
        ResendConfirmationMail(user){
            sendEmailVerification(user)
            this.ShowNotification('success', 'SE HA VUELTO A ENVIAR EL CORREO DE VERIFICACIÓN');
        },

        ValidateForm(){
            let valids = [];

            this.fields.forEach(field=>{
                valids.push(this.$refs[field].GetValid());
            });

            return (valids.includes(false) ? false : true);
        },

        SignIn(){
            if(!this.ValidateForm()){
                this.ShowNotification('error', 'ES NECESARIO LLENAR LOS CAMPOS OBLIGATORIOS CON DATOS VÁLIDOS');
                return;
            }

            try{
              signOut(this.auth);

              signInWithEmailAndPassword(this.auth, this.$refs.txtEmail.GetValue(), this.$refs.txtPassword.GetValue())
              .catch((e) => {
                let firebaseMessage = this.firebaseErrors[e.code];
                this.ShowNotification('error', firebaseMessage ? firebaseMessage : 'OCURRIÓ UN ERROR AL INTENTAR AUTENTICAR AL USUARIO');
              })
            }
            catch(e){
                let firebaseMessage = this.firebaseErrors[e.code];
                this.ShowNotification('error', firebaseMessage ? firebaseMessage : 'OCURRIÓ UN ERROR AL INTENTAR AUTENTICAR AL USUARIO');
            }
        },

        SignInWithGoogle(){
          signOut(this.auth)

          signInWithPopup(this.auth, this.googleProvider)
          .catch((e) => {
              let firebaseMessage = this.firebaseErrors[e.code];
              this.ShowNotification('error', firebaseMessage ? firebaseMessage : 'OCURRIÓ UN ERROR AL INTENTAR AUTENTICAR AL USUARIO');
          })
        }
      }
}
</script>

<style>
#app-container {
  height: calc(100% - env(safe-area-inset-bottom)); /* Resta el alto del footer nativo */
  overflow: auto; /* Permite el desplazamiento vertical del contenido */
}

[disabled] {
  cursor: not-allowed;
}

.login{
  background: rgb(244,249,255);
  background: radial-gradient(circle at top -30px left 40%, rgba(244,249,255,1) 5%, rgba(127,130,145,1) 25%, rgba(48,48,58,1) 50%, rgba(31,31,44,1) 60%, rgba(17,17,19,1) 80%);
}

.fullWidth{
    width: 100%;
    height: 50px;
    border-radius: 0px;
    margin: 0px;
}

.mobile{
    padding: 0px;
}

.modal-body b{
  margin-right: 5px;
}

.info-paragraph{
  border-bottom: 1px solid lightgray;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.info{
  color: var(--Info);
  margin-right: 3px;
  font-size: 14px;
}

.iconButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:transparent;
    border-radius: 50%;
    border: 0px;
    height: 32px;
    width: 32px;
    margin: 0px;
}

.iconButton i {
    font-size: 20px !important;
    line-height: 0;
}

.iconButton:hover {
    background-color: rgb(0, 0, 0, 0.05);
}

.sticky-navbar {
  position: sticky;
  top: 0;
  z-index: 100;
}

:root{
  --PretorGrey: rgb(30, 30, 30);
  --PretorLightGrey: rgb(63, 63, 75);
  --Info: rgb(58, 92, 212);

  --LativoLightPurple: rgb(222, 228, 255);
  --LativoDarkPurple: rgb(31, 31, 44);
}

body{
  margin: 0;
  font-family: 'Lato', sans-serif;
  background-color: var(--LativoLightPurple);
}

.pretorCard{
  margin: 15px; 
  width: calc(100%-30px); 
  border-radius: 15px; 
  background-color: white; 
  box-shadow: 0 5px 5px 3px rgba(0, 0, 0, 0.3); 
  height: calc(100vh - 60px - 30px);
}

.pretorCard.mobile{
  margin: 5px !important;
  border-radius: 0px;
  box-shadow: none;
  background-color: transparent;
  height: min-content;
  max-height: min-content;
}

.pretorCard.mobile .header{
  display: block;
  background-color: var(--LativoLightPurple);
  position: sticky;
  z-index: 800;
  top: 60px;
}

.pretorCard.mobile .header .title{
  font-size: 20px;
}

.pretorCard .header{
  display: flex;
  align-items: center;
  padding: 15px;
  align-content: center;
}

.pretorCard .header .title{
  font-weight: bold !important;
  font-size: 16px !important;
  color: black !important;
}

.input-with-icon {
    position: relative;
}

.input-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-right: 0px;
    transform: translateY(-50%);
    color: gray;
    font-size: 12px;
}

.pretorForm{
  min-height: 36px;
  border-radius: 5px;
  border: 1px solid rgb(222, 222, 223);
  padding-left: 10px;
  outline: none;
  width: 100%;
  font-size: 14px;
}

.invalid{
  border: 1px solid rgb(237, 194, 188) !important;
  background-color: rgb(255, 246, 246) !important;
  color: rgb(224, 180, 180) !important;
}

.pretorForm::placeholder{
  color: rgb(178, 179, 179);
}

.invalid::placeholder{
  color: rgb(224, 180, 180)
}

.pretorModal{
  z-index: 9999 !important;
}

.pretorModal label{
  font-size: 12px;
  color: gray;
}

.pretorModal .pretorForm{
  margin-bottom: 15px;
}

.pretorModal .modal-header{
  padding-bottom: 10px;
}

/* Estilos generales de la scrollbar */
::-webkit-scrollbar {
  width: 8px; /* Ancho de la scrollbar */
  background-color: #F0F0F0; /* Color de fondo de la scrollbar */
}

/* Estilo del thumb (barra deslizante) de la scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #C0C0C0; /* Color del thumb */
  border-radius: 4px; /* Radio de borde del thumb */
}

/* Estilo del thumb al pasar el mouse sobre la scrollbar */
::-webkit-scrollbar-thumb:hover {
  background-color: #A0A0A0; /* Color del thumb al pasar el mouse */
}

/* Estilo del thumb cuando está presionado */
::-webkit-scrollbar-thumb:active {
  background-color: #808080; /* Color del thumb al estar presionado */
}

/* Estilo de la scrollbar en su estado inactivo */
::-webkit-scrollbar-track {
  background-color: #F0F0F0; /* Color de fondo del track (área no ocupada por el thumb) */
}

/* Estilo de la scrollbar inactiva */
::-webkit-scrollbar-track:no-button:no-thumb {
  background-color: rgb(193, 193, 193); /* Color de la scrollbar inactiva */
}
</style>