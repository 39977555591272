import {createRouter, createWebHistory} from 'vue-router'
import { useStore } from '@/store/DefaultStore.js';
//import { getAuth, onAuthStateChanged  } from 'firebase/auth';

// const auth = getAuth();

// const waitForAuthChange = () => {
//   return new Promise((resolve) => {
//     const unsubscribe = onAuthStateChanged(auth, () => {
//       unsubscribe(); // Deja de escuchar los cambios después del primer cambio
//       resolve();
//     });
//   });
// };

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/inicio',
      name: 'home',
      component: () => import('../components/Views/Home/HomeView.vue'),
      meta: {
        requiresAuth: true
      } 
    },
    {
      path: '/expedientes',
      name:'files',
      component: () => import('../components/Views/Files/FilesView.vue'),
      meta:{
        requiresAuth: true
      }
    },
    {
      path: '/expediente/:PKFile/:PKTask?/:PKDocument?/:PKAgreement?',
      name: 'fileDetail',
      component: () => import('../components/Views/Files/FileDetail/FileDetail.vue'),
      props: true,
      meta:{
        requiresAuth: true
      }
    },
    {
      path: '/calendario',
      name: 'calendar',
      component: () => import('../components/Views/Calendar/CalendarView.vue'),
      meta:{
        requiresAuth: true
      }
    },
    {
      path: '/acuerdos',
      name: 'agreements',
      component: () => import('../components/Views/Agreements/AgreementsView.vue'),
      meta:{
        requiresAuth: true
      }
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('../components/Views/Users/UsersView.vue'),
      meta:{
        requiresAuth: true
      }
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('../components/Views/Login/RegisterView.vue'),
      meta: {
        requiresAuth: false,
        hideNavbar: true
      }
    },
    {
      path: "/:catchAll(.*)",
      name:'notFound',
      component: () => import('../components/Views/Files/FilesView.vue'),
      meta: {
        requiresAuth: false
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const store = useStore();

  if((to.name == 'home' || to.name == 'calendar' || to.name == 'agreements') && store.isCustomer){
    next({name: 'files'});
    return;
  }

  if(to.name == 'users' && !store.isAdmin){
    next({name: 'files'});
    return;
  }

  next();
});

export default router